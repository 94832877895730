import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_new_target",
  ref: "newTargetModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-header pb-0 border-0 justify-content-end" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body scroll-y px-10 px-lg-15 pt-0 pb-15" }
const _hoisted_8 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_9 = { class: "row g-9 mb-8" }
const _hoisted_10 = { class: "col-md-6 fv-row" }
const _hoisted_11 = { class: "col-md-6 fv-row" }
const _hoisted_12 = { class: "position-relative align-items-center" }
const _hoisted_13 = { class: "symbol symbol-20px me-4 position-absolute ms-4" }
const _hoisted_14 = { class: "symbol-label bg-secondary" }
const _hoisted_15 = { class: "svg-icon" }
const _hoisted_16 = { class: "d-flex flex-column mb-8" }
const _hoisted_17 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_18 = { class: "text-center" }
const _hoisted_19 = ["data-kt-indicator"]
const _hoisted_20 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_21 = { class: "svg-icon svg-icon-3 ms-2 me-0" }
const _hoisted_22 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_form, {
            id: "kt_modal_new_target_form",
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
            model: _ctx.targetData,
            rules: _ctx.rules,
            ref: "formRef",
            class: "form"
          }, {
            default: _withCtx(() => [
              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mb-13 text-center" }, [
                _createElementVNode("h1", { class: "mb-3" }, "Set First Target"),
                _createElementVNode("div", { class: "text-gray-400 fw-bold fs-5" }, [
                  _createTextVNode(" If you need more info, please check "),
                  _createElementVNode("a", {
                    href: "#",
                    class: "fw-bolder link-primary"
                  }, "Project Guidelines"),
                  _createTextVNode(". ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_8, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold mb-2" }, [
                  _createElementVNode("span", { class: "required" }, "Target Title"),
                  _createElementVNode("i", {
                    class: "fas fa-exclamation-circle ms-2 fs-7",
                    "data-bs-toggle": "tooltip",
                    title: "Specify a target name for future usage and reference"
                  })
                ], -1)),
                _createVNode(_component_el_form_item, { prop: "targetTitle" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.targetData.targetTitle,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetData.targetTitle) = $event)),
                      placeholder: "Enter Target Title",
                      name: "targetTitle"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", { class: "required fs-6 fw-bold mb-2" }, "Assign", -1)),
                  _createVNode(_component_el_form_item, { prop: "assign" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.targetData.assign,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.targetData.assign) = $event)),
                        placeholder: "Select a Team Member",
                        name: "assign",
                        as: "select"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_option, { value: "" }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Select user...")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_option, {
                            label: "Karina Clark",
                            value: "1"
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("Karina Clark")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_option, {
                            label: "Robert Doe",
                            value: "2"
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("Robert Doe")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_option, {
                            label: "Niel Owen",
                            value: "3"
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("Niel Owen")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_option, {
                            label: "Olivia Wild",
                            value: "4"
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Olivia Wild")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_el_option, {
                            label: "Sean Bean",
                            value: "5"
                          }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("Sean Bean")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", { class: "required fs-6 fw-bold mb-2" }, "Due Date", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen025.svg" })
                        ])
                      ])
                    ]),
                    _createVNode(_component_el_form_item, { prop: "dueDate" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.targetData.dueDate,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.targetData.dueDate) = $event)),
                          placeholder: "Select a date",
                          name: "dueDate"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { class: "fs-6 fw-bold mb-2" }, "Target Details", -1)),
                _createVNode(_component_el_form_item, { prop: "targetDetails" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.targetData.targetDetails,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.targetData.targetDetails) = $event)),
                      type: "textarea",
                      rows: "3",
                      name: "targetDetails",
                      placeholder: "Type Target Details"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[16] || (_cache[16] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold mb-2" }, [
                  _createElementVNode("span", { class: "required" }, "Tags"),
                  _createElementVNode("i", {
                    class: "fas fa-exclamation-circle ms-2 fs-7",
                    "data-bs-toggle": "tooltip",
                    title: "Specify a target priorty"
                  })
                ], -1)),
                _createVNode(_component_el_form_item, { prop: "tags" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.targetData.tags,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.targetData.tags) = $event)),
                      multiple: "",
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      placeholder: "Choose tags for your target"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          label: "Important",
                          value: "important"
                        }),
                        _createVNode(_component_el_option, {
                          label: "Urgent",
                          value: "urgent"
                        }),
                        _createVNode(_component_el_option, {
                          label: "high",
                          value: "high"
                        }),
                        _createVNode(_component_el_option, {
                          label: "low",
                          value: "low"
                        }),
                        _createVNode(_component_el_option, {
                          label: "medium",
                          value: "medium"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "d-flex flex-stack mb-8" }, [
                _createElementVNode("div", { class: "me-5" }, [
                  _createElementVNode("label", { class: "fs-6 fw-bold" }, "Adding Users by Team Members"),
                  _createElementVNode("div", { class: "fs-7 fw-bold text-gray-400" }, " If you need more info, please check budget planning ")
                ]),
                _createElementVNode("label", { class: "form-check form-switch form-check-custom form-check-solid" }, [
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "1",
                    checked: "checked"
                  }),
                  _createElementVNode("span", { class: "form-check-label fw-bold text-gray-400" }, " Allowed ")
                ])
              ], -1)),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "mb-15 fv-row" }, [
                _createElementVNode("div", { class: "d-flex flex-stack" }, [
                  _createElementVNode("div", { class: "fw-bold me-5" }, [
                    _createElementVNode("label", { class: "fs-6" }, "Notifications"),
                    _createElementVNode("div", { class: "fs-7 text-gray-400" }, " Allow Notifications by Phone or Email ")
                  ]),
                  _createElementVNode("div", { class: "d-flex align-items-center" }, [
                    _createElementVNode("label", { class: "form-check form-check-custom form-check-solid me-10" }, [
                      _createElementVNode("input", {
                        class: "form-check-input h-20px w-20px",
                        type: "checkbox",
                        name: "communication[]",
                        value: "email",
                        checked: "checked"
                      }),
                      _createElementVNode("span", { class: "form-check-label fw-bold" }, " Email ")
                    ]),
                    _createElementVNode("label", { class: "form-check form-check-custom form-check-solid" }, [
                      _createElementVNode("input", {
                        class: "form-check-input h-20px w-20px",
                        type: "checkbox",
                        name: "communication[]",
                        value: "phone"
                      }),
                      _createElementVNode("span", { class: "form-check-label fw-bold" }, " Phone ")
                    ])
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_18, [
                _cache[19] || (_cache[19] = _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_new_target_cancel",
                  class: "btn btn-white me-3"
                }, " Cancel ", -1)),
                _createElementVNode("button", {
                  "data-kt-indicator": _ctx.loading ? 'on' : null,
                  class: "btn btn-lg btn-primary",
                  type: "submit"
                }, [
                  (!_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                        _cache[17] || (_cache[17] = _createTextVNode(" Submit ")),
                        _createElementVNode("span", _hoisted_21, [
                          _createVNode(_component_inline_svg, { src: "icons/duotune/arrows/arr064.svg" })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, _cache[18] || (_cache[18] = [
                        _createTextVNode(" Please wait... "),
                        _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_19)
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])
      ])
    ])
  ], 512))
}